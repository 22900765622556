import React, { useReducer, useContext, useEffect, useRef } from 'react';
// import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
// import { CSSTransition } from 'react-transition-group';
// import { useCookies } from 'react-cookie';
// import { useAnimation } from "react-animatable";
// import { TwitterShareButton, TwitterIcon } from 'react-share';
import './App.css';
// import logoMonachen from './monachen.png';
import logoMonacotto from './monacotto.png';
import logoKOM from './KOM.png';
import imageMonatoka from './monatoka.png';
import imageMagicShop from './magigShopTransparent.png';

let GlobalState;


function App() {

  const initialState = {
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  // const navigate = useNavigate();
  // const [cookies, setCookie, removeCookie] = useCookies();
  GlobalState = React.createContext([state, dispatch]);


  return (
    <div className="App">
      <AboutUs />
    </div>
  );
}

function AboutUs() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className='' >
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter' >
        <div className='flexRow justifyContentCenter '>
          <img className='lpMainImage' src={imageMonatoka} />
          <div className='lpMainImage flexColumn justifyContentCenter alignItemsCenter '>
            <div className='font2 marginTopBottom1' >
              {/*
                モナコインを世の中に広める<br/>
                とか、そうゆう仕事をする会社です。
              */}
              ビットコインの正しい知識と、<br/>
              モナコインの楽しさを広めます！
            </div>
            {/*
              <div className='font1p5 marginTopBottom1' >
                モナコインは、2013年12月に日本で誕生した暗号資産です。ビットコインと同様、管理・運営者のいない非中央集権的暗号資産であり、ビットコインのソースコードを基に作られているため、日本人が最初に触れる暗号資産としてふさわしいとモナトカは考えます。モナトカは、モナコインを通じて暗号資産の普及を促進していきます。
              </div>
            */}
          </div>
        </div>
        <div className='flexRow justifyContentCenter '>
          {/* <div className='flexColumn justifyContentFlexStart alignItemsFlexStart margin1'> */}
          <div className='lpMainImage flexColumn justifyContentCenter alignItemsCenter '>
            <div className='flexColumn justifyContentCenter alignItemsFlexStart '>
              <div className='textLeft margin1 font1p5'>
                提供サービス
              </div>
              {/*
                <div className='flexColumn alignItemsFlexStart margin1 '>
                  <div className='marginSide1'>
                    モナコイン決済でのJPYCの販売・買取
                  </div>
                  <button className='borderNone backgroundColorTransparent margin1 cursor'
                    onClick={ () => {
                      window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}`);
                    }}
                  >
                    <img className='width24' src={logoMonachen} />
                  </button>
                </div>
              */}
              <div className='flexColumn alignItemsFlexStart margin1 '>
                <div className='marginSide1'>
                  モナカード マーケットプレイス
                </div>
                <button className='borderNone backgroundColorTransparent margin1 cursor'
                  onClick={ () => {
                    window.open(`https://${process.env.REACT_APP_MONACOTTO_MAIN_URL}`);
                  }}
                >
                  <img className='width24' src={logoMonacotto} />
                </button>
              </div>
              <div className='flexColumn alignItemsFlexStart margin1 '>
                <div className='marginSide1'>
                  モナカードでAIカードバトル！
                </div>
                <button className='borderNone backgroundColorTransparent margin1 cursor'
                  onClick={ () => {
                    window.open(`https://${process.env.REACT_APP_KOM_MAIN_URL}`);
                  }}
                >
                  <img className='width24' src={logoKOM} />
                </button>
              </div>
            </div>
          </div>
          <img className='lpMainImage' src={imageMagicShop} />
        </div>
        <div className='flexRow justifyContentCenter '>
          <div className='flexColumn margin1 ' >
            <div className='flexRow margin1'>
              <div className='width10'>
                会社名
              </div>
              <div className=''>
                株式会社モナトカ
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                代表取締役
              </div>
              <div className=''>
                渡邉潤平
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                所在地
              </div>
              <div className=''>
                〒113-0033 東京都文京区本郷3–38–1本郷信徳ビル7階 HashHub内
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                設立
              </div>
              <div className=''>
                2022年2月1日
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                資本金
              </div>
              <div className=''>
                5,000,000円
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                事業内容
              </div>
              <div className=''>
                {/* 前払式支払手段の販売・買取、モナカードのマーケットプレイスの提供 等 */}
                モナカードのマーケットプレイスの提供、オンラインゲームの提供 等
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                顧問税理士
              </div>
              <div className=''>
                税理士法人　堀口会計
              </div>
            </div>
            {/*
            <button className='borderNone backgroundColorTransparent font2 cursor marginTop1' onClick={ () => navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/main' : '/main') }>
              {words.useMonachen[state.language]}
            </button>
            */}
          </div>
          <div className='lpMainImage flexColumn justifyContentFlexStart alignItemsFlexStart '>
            <div className='textLeft margin1 font1p5'>
              プレスリリース
            </div>
            {/*
              <div className='flexColumn alignItemsFlexStart margin1 '>
                <div className='marginSide1'>
                  2022年6月14日
                </div>
                <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
                  onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000002.000100288.html'); } }
                >
                  モナコインでJPYCが買える・売れるサービス「もなちぇん」。取引上限額が500MONA(※1)にアップ！
                </button>
              </div>
            */}
            <div className='flexColumn alignItemsFlexStart margin1 '>
              <div className='marginSide1'>
                2022年10月28日
              </div>
              <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
                onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000003.000100288.html'); } }
              >
                モナコイン(N)FT「モナカード」のマーケットプレイス「もなこっと」が誕生！
              </button>
            </div>
            <div className='flexColumn alignItemsFlexStart margin1 '>
              <div className='marginSide1'>
                2023年4月1日
              </div>
              <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
                onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000004.000100288.html'); } }
              >
                「自分の財産は自分で守る」― ビットコイン、モナコインなどの暗号資産の普及を目指します。
              </button>
            </div>
            <div className='flexColumn alignItemsFlexStart margin1 '>
              <div className='marginSide1'>
                2023年6月22日
              </div>
              <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
                onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000005.000100288.html'); } }
              >
                ChatGPTとモナカードで融合召喚！新感覚AIカードバトルゲーム「Knights of Monadom」誕生！！
              </button>
            </div>
            <div className='flexColumn alignItemsFlexStart margin1 '>
              <div className='marginSide1'>
                2023年7月31日
              </div>
              <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
                onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000006.000100288.html'); } }
              >
                ChatGPT＆モナカードゲーム「Knights of Monadom」一般クリエイターカード登録受付開始！
              </button>
            </div>
            <div className='flexColumn alignItemsFlexStart margin1 '>
              <div className='marginSide1'>
                2023年9月29日
              </div>
              <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
                onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000007.000100288.html'); } }
              >
                ChatGPTカードバトルゲーム「Knights of Monadom(愛称モナダム)」に魔法カードが加わりました！！
              </button>
            </div>
            <div className='flexColumn alignItemsFlexStart margin1 '>
              <div className='marginSide1'>
                2023年11月30日
              </div>
              <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
                onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000008.000100288.html'); } }
              >
                ChatGPTカードバトルゲーム「Knights of Monadom(愛称モナダム)」が日本円で遊べるようになりました！
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn alignItemsCenter width98vw padding1'>
        <img className='widthMax' src={imageMonatoka} />
        <div className='widthMax flexColumn justifyContentCenter alignItemsCenter '>
          <div className='font2 marginTopBottom1' >
            {/*
              モナコインを世の中に広める<br/>
              とか、そうゆう仕事をする会社です。
            */}
            ビットコインの正しい知識と、<br/>
            モナコインの楽しさを広めます！
          </div>
          {/*
            <div className='font1p5 marginTopBottom1' >
              モナコインは、2013年12月に日本で誕生した暗号資産です。ビットコインと同様、管理・運営者のいない非中央集権的暗号資産であり、ビットコインのソースコードを基に作られているため、日本人が最初に触れる暗号資産としてふさわしいとモナトカは考えます。モナトカは、モナコインを通じて暗号資産の普及を促進していきます。
            </div>
          */}
        </div>
        {/* <div className='flexColumn justifyContentFlexStart alignItemsFlexStart margin1'> */}
        <div className='flexColumn justifyContentCenter alignItemsFlexStart maxWidthMax'>
          <div className='textLeft margin1 font1p5'>
            提供サービス
          </div>
          {/*
            <div className='flexColumn alignItemsFlexStart margin1 '>
              <div className='marginSide1'>
                モナコイン決済でのJPYCの販売・買取
              </div>
              <button className='borderNone backgroundColorTransparent margin1 cursor'
                onClick={ () => {
                  window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}`);
                }}
              >
                <img className='width24' src={logoMonachen} />
              </button>
            </div>
          */}
          <div className='flexColumn alignItemsFlexStart margin1 '>
            <div className='marginSide1'>
              モナカード マーケットプレイス
            </div>
            <button className='borderNone backgroundColorTransparent margin1 cursor'
              onClick={ () => {
                window.open(`https://${process.env.REACT_APP_MONACOTTO_MAIN_URL}`);
              }}
            >
              <img className='width85PC' src={logoMonacotto} />
            </button>
          </div>
          <div className='flexColumn alignItemsFlexStart margin1 '>
            <div className='marginSide1'>
              モナカードでAIカードバトル！
            </div>
            <button className='borderNone backgroundColorTransparent margin1 cursor'
              onClick={ () => {
                window.open(`https://${process.env.REACT_APP_KOM_MAIN_URL}`);
              }}
            >
              <img className='width85PC' src={logoKOM} />
            </button>
          </div>
        </div>
        <img className='widthMax' src={imageMagicShop} />
        <div className='flexColumn alignItemsFlexStart widthMax margin1 ' >
          <div className='flexRow margin1'>
            <div className='width10'>
              会社名
            </div>
            <div className=''>
              株式会社モナトカ
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width10'>
              代表取締役
            </div>
            <div className=''>
              渡邉潤平
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width10'>
              所在地
            </div>
            <div className=''>
              〒113-0033 東京都文京区本郷3–38–1本郷信徳ビル7階 HashHub内
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width10'>
              設立
            </div>
            <div className=''>
              2022年2月1日
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width10'>
              資本金
            </div>
            <div className=''>
              5,000,000円
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width10'>
              事業内容
            </div>
            <div className=''>
              {/* 前払式支払手段の販売・買取、モナカードのマーケットプレイスの提供 等 */}
              モナカードのマーケットプレイスの提供、オンラインゲームの提供 等
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width10'>
              顧問税理士
            </div>
            <div className=''>
              税理士法人　堀口会計
            </div>
          </div>
          {/*
          <button className='borderNone backgroundColorTransparent font2 cursor marginTop1' onClick={ () => navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/main' : '/main') }>
            {words.useMonachen[state.language]}
          </button>
          */}
        </div>
        <div className='widthMax flexColumn justifyContentFlexStart alignItemsFlexStart '>
          <div className='textLeft margin1 font1p5'>
            プレスリリース
          </div>
          {/*
            <div className='flexColumn alignItemsFlexStart margin1 '>
              <div className='marginSide1'>
                2022年6月14日
              </div>
              <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
                onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000002.000100288.html'); } }
              >
                モナコインでJPYCが買える・売れるサービス「もなちぇん」。取引上限額が500MONA(※1)にアップ！
              </button>
            </div>
          */}
          <div className='flexColumn alignItemsFlexStart margin1 '>
            <div className='marginSide1'>
              2022年10月28日
            </div>
            <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
              onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000003.000100288.html'); } }
            >
              モナコイン(N)FT「モナカード」のマーケットプレイス「もなこっと」が誕生！
            </button>
          </div>
          <div className='flexColumn alignItemsFlexStart margin1 '>
            <div className='marginSide1'>
              2023年4月1日
            </div>
            <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
              onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000004.000100288.html'); } }
            >
              「自分の財産は自分で守る」― ビットコイン、モナコインなどの暗号資産の普及を目指します。
            </button>
          </div>
          <div className='flexColumn alignItemsFlexStart margin1 '>
            <div className='marginSide1'>
              2023年6月22日
            </div>
            <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
              onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000005.000100288.html'); } }
            >
              ChatGPTとモナカードで融合召喚！新感覚AIカードバトルゲーム「Knights of Monadom」誕生！！
            </button>
          </div>
          <div className='flexColumn alignItemsFlexStart margin1 '>
            <div className='marginSide1'>
              2023年7月31日
            </div>
            <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
              onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000006.000100288.html'); } }
            >
              ChatGPT＆モナカードゲーム「Knights of Monadom」一般クリエイターカード登録受付開始！
            </button>
          </div>
          <div className='flexColumn alignItemsFlexStart margin1 '>
            <div className='marginSide1'>
              2023年9月29日
            </div>
            <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
              onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000007.000100288.html'); } }
            >
              ChatGPTカードバトルゲーム「Knights of Monadom(愛称モナダム)」に魔法カードが加わりました！！
            </button>
          </div>
          <div className='flexColumn alignItemsFlexStart margin1 '>
            <div className='marginSide1'>
              2023年11月30日
            </div>
            <button className='borderNone backgroundColorTransparent margin1 cursor fontHyperLink textLeft'
              onClick={ () => { window.open('https://prtimes.jp/main/html/rd/p/000000008.000100288.html'); } }
            >
              ChatGPTカードバトルゲーム「Knights of Monadom(愛称モナダム)」が日本円で遊べるようになりました！
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// ------------------------------------------------------------------------------------------------------------

// DECIMAL ADJUST
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  // If value is Infinity...
  if (value === Infinity) {
    return Infinity;
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

// REDUCER
function reducer(state, action) {
  let newState = { ...state };
  let numericCharacter;
  let number;
  let amountMona;
  let amountJpyc;
  let isChanged;
  let items;
  let item;
  let newIndex;

  switch (action.type) {
    case 'setState':
      newState[action.key] = action.value;
      return newState;

    case 'setStateMultiLayers':
      action.keys.concat([action.value]).reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc;
        }
        if (idx === src.length - 2) {
          return acc[cur] = src[idx + 1];
        }
        else {
          return acc[cur];
        }
      }, newState );
      return newState;

    case 'setStateNum':
      number = parseInt(action.value, 10);

      if (Number.isNaN(number)) {
        newState[action.key] = ''
      }
      else {
        newState[action.key] = number;
      }

      return newState;

    case 'setStateMultiLayersNum':
      number = parseInt(action.value, 10);

      if (Number.isNaN(number)) {
        number = ''
      }

      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc[cur] = number;
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    case 'setStateFloat':
      number = parseFloat(action.value);

      if (Number.isNaN(number)) {
        newState[action.key] = ''
      }
      else {
        newState[action.key] = number;
      }

      return newState;

    // setStateMultiLayersFloat
    case 'setStateMultiLayersFloat':
      numericCharacter = action.value.replace(/[^\d.]/, '');
      number = parseFloat(numericCharacter);

      if (Number.isNaN(number)) {
        number = ''
      }
      else if (action.adjustType !== undefined) {
        number = decimalAdjust(action.adjustType, number, action.adjustExp);
      }

      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          acc[cur].face = numericCharacter;
          acc[cur].value = number;
          return acc[cur];
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    // adjustFace
    case 'adjustFace':
      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          acc[cur].face = acc[cur].value;
          return acc[cur];
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    // pushOut
    case 'pushOut':
      const target = action.targetKeys.reduce( (acc, cur) => {
          return acc[cur];
      }, newState );

      action.keys.reduce( (acc, cur, idx, src) => {
        const pushedOut = target[cur];
        target[cur] = acc;
        return  pushedOut;
      }, action.value );

      return newState;

    case 'setStateIfChange':
      if (newState[action.key] != action.value) {
        newState[action.key] = action.value;
        return newState;
      }
      else {
        return state;
     }

    case 'setStateMultiLayersIfChange':
      isChanged = action.keys.concat([action.value]).reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc;
        }
        if (idx === src.length - 2) {
          if (acc[cur] !== src[idx + 1]) {
            acc[cur] = src[idx + 1];
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return acc[cur];
        }
      }, newState );

      if (isChanged) {
        return newState;
      }
      else {
        return state;
      }

    // setNotification
    case 'setNotification':
      newState[action.key].body.push(action.value);

      if (newState[action.key].body.length > state.config.clientParameters.notificationMaxLength) {
        newState[action.key].body.shift();
      }

      newState[action.key].index = newState[action.key].body.length -1;

      newState[action.key].inAnimation = true;

      return newState;

    case 'stopNotificationAnimation':
      newState[action.key].inAnimation = false;
      return newState;

    // itemReplace
    case 'itemReplace':
      items = newState.items[newState.gallery.addressMain].itemPlacement[0].items;
      item = items[action.index];

      if (action.direction === 'left') {
        newIndex = action.index - 1 >= 0 ? action.index -1 : items.length - 1;
      }
      else { // right
        newIndex = action.index + 1 <= items.length - 1 ? action.index + 1 : 0;
      }

      items.splice(action.index, 1);
      items.splice(newIndex, 0, item);

      return newState;

    // default
    default:
      return state;
  }
}


export default App;
